import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

export default function DigitalMarketingPage() {
    return (
        <Layout>
            <div id='DigitalMarketing_page'>
                <div id='DigitalMarketing_banner'>
                    <h1>Digital<br />Marketing</h1>
                    <h3>Your digital footprint is how customers will recognize you. At CMS, we make sure your footprint is <span className='emphasisText'>CONSISTENT</span>, <span className='emphasisText'>CLEAR</span>, and <span className='emphasisText'>CONCISE</span>. The 3 C’s mixed with our talented distribution team ensures your brand will be seen and heard by customers.</h3>
                    <Link to='/Pricing/'>
                        <button>Plans & Pricing</button>
                    </Link>
                </div>
                <h1>Our Services</h1>
                <div id='digitalMarketing_container'>
                    <div>
                        <img alt='digitalcontentcreation.png' src='https://i.imgur.com/KwWYB3U.png'></img>
                        <p>Digital Content Creation</p>
                    </div>
                    <div>
                        <img alt='onlineads.png' src='https://i.imgur.com/dYXhN3E.png'></img>
                        <p>Online Advertising</p>
                    </div>
                    <div>
                        <img alt='data.png' src='https://i.imgur.com/kF7jEhJ.png'></img>
                        <p>In-Depth Data Analysis</p>
                    </div>
                    <div>
                        <img alt='ecommerce.png' src='https://i.imgur.com/eNaFIil.png'></img>
                        <p>Ecommerce Management</p>
                    </div>
                </div>
                <div id='digitalMarketing_digitalContent'>
                    <h3>We create digital content for you.</h3>
                    <div>
                        <img alt='emailmarketing.png' src='https://i.imgur.com/jaKRrce.png'></img>
                        <p>Email<br />Marketing Service</p>
                    </div>
                    <div>
                        <img alt='socialmanagement.png' src='https://i.imgur.com/T2R2V2L.png'></img>
                        <p>Organic<br />Social Management</p>
                    </div>
                    <div>
                        <img alt='branding.png' src='https://i.imgur.com/IRYHc1I.png'></img>
                        <p>Branding and<br />Rebranding Sessions</p>
                    </div>
                    <img alt='digitalcontentphone.png' src='https://i.imgur.com/zJSZBgM.png'></img>
                </div>
                <div id='digitalContentAd_container'>
                    <h3>Effective advertising built by our expert team</h3>
                    <div>
                        <img alt='facebookicon.png' src='https://i.imgur.com/IiOsiBH.png'></img>
                        <img alt='twittericon.png' src='https://i.imgur.com/398CNN9.png'></img>
                        <img alt='linkedinicon.png' src='https://i.imgur.com/lCKciS6.png'></img>
                        <img alt='snapchaticon.png' src='https://i.imgur.com/7NmAJBL.png'></img>
                        <img alt='instagramicon.png' src='https://i.imgur.com/t8oB5TM.png'></img>
                        <img alt='youtubeicon.png' src='https://i.imgur.com/9dB4dUO.png'></img>
                    </div>
                </div>
                <div id='socialmediacampaign_container'>
                    <div>
                        <img alt='checkmark.png' src='https://i.imgur.com/PRIn7qE.png'></img>
                        <p>Social Media Ad Creations</p>
                    </div>
                    <div>
                        <img alt='checkmark.png' src='https://i.imgur.com/PRIn7qE.png'></img>
                        <p>Google PPC Campaign</p>
                    </div>
                </div>
                <div id='datamonitoring_container'>
                    <img alt='datamonitoringtablet.png' src='https://i.imgur.com/JXMZWmm.png'></img>
                    <div>
                        <h5>Data monitoring and analysis.</h5>
                        <img alt='upwardgraph.png' src='https://i.imgur.com/yxV5hX4.png'></img>
                        <p>Marketplace SKU Management</p>
                        <p>Digital Footprint Audits</p>
                    </div>
                </div>
                <h3 id='ecommerce--title'>Ecommerce Management</h3>
                <h4 id='ecommerce--text'>Our founders both got their start in e-Commerce and know the landscape. CMS will set up, manage, market, and excel your e-Commerce business. Our approach allows us to get your SKUs and services in front of as many eyes as possible. Our easy checkout system helps you slash your cart abandonment rate in half. Let us grow your online business into the financial success it can become!</h4>
                <img id='ecommerce--img' alt='eccommerceimg.png' src='https://i.imgur.com/1OXYh18.png'></img>
                <h3 id='ecommerce--subtitle'>See how our services can help your business grow</h3>
                <div className='digitalMarketingService_container'>
                    <div>
                        <img alt='SEOlearnmore.png' src='https://i.imgur.com/bvcpmSZ.png'></img>
                        <h3>Search Engine Optimization</h3>
                        <Link to='/SEO/'>
                            <button>Learn More &#62;</button>
                        </Link>
                    </div>
                    <div>
                        <img alt='Webdesignlearnmore.png' src='https://i.imgur.com/qcchRmG.png'></img>
                        <h3>Website<br />Design</h3>
                        <Link to='/WebDesign/'>
                            <button>Learn More &#62;</button>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}